var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-container",
        [
          _c(
            "el-main",
            [
              _c("head-layout", {
                attrs: {
                  "head-btn-options": _vm.headBtnOptions,
                  "head-title": "维度2分类",
                },
                on: {
                  "head-save": _vm.headSave,
                  "head-save-back": function ($event) {
                    return _vm.headSave(true)
                  },
                  "head-cancel": _vm.headCancel,
                },
              }),
              _c("form-layout", {
                ref: "formLayout",
                attrs: { column: _vm.formColumn, dataForm: _vm.dataForm },
                on: {
                  "update:dataForm": function ($event) {
                    _vm.dataForm = $event
                  },
                  "update:data-form": function ($event) {
                    _vm.dataForm = $event
                  },
                },
              }),
              _c("head-layout", {
                attrs: {
                  "head-title": "知识分类",
                  "head-btn-options": _vm.headBtn,
                },
                on: {
                  "head-add-tabs": _vm.headAddTabs,
                  "head-remove-tabs": _vm.headRemoveTabs,
                },
              }),
              _c("grid-head-layout", {
                attrs: { "search-columns": _vm.searchColumns },
                on: {
                  "grid-head-search": _vm.searchChange,
                  "grid-head-empty": _vm.searchReset,
                },
              }),
              _c("grid-layout", {
                ref: "gridLayOut",
                attrs: {
                  "grid-row-btn": _vm.gridRowBtn,
                  "table-options": _vm.tableOptions,
                  "table-data": _vm.tableData,
                  "table-loading": _vm.tableLoading,
                  "data-total": _vm.page.total,
                  page: _vm.page,
                },
                on: {
                  "page-current-change": _vm.handleCurrentChange,
                  "page-size-change": _vm.handleSizeChange,
                  "page-refresh-change": _vm.onLoad,
                  "gird-handle-select-click": _vm.selectionChange,
                  "row-remove": _vm.rowRemove,
                  "row-save": _vm.rowUpdate,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.showDialog
        ? _c(
            "common-dialog",
            {
              attrs: {
                width: "60%",
                dialogTitle: "知识分类",
                "show-confirm-btn": _vm.formType != "view",
              },
              on: { cancel: _vm.closeDialog, confirm: _vm.handleNodeSave },
            },
            [
              _c("avue-form", {
                ref: "addFormList",
                attrs: { option: _vm.dialogOption },
                on: { submit: _vm.saveList },
                model: {
                  value: _vm.formList,
                  callback: function ($$v) {
                    _vm.formList = $$v
                  },
                  expression: "formList",
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }